import React from 'react';
import { useSelector } from 'react-redux';

import { CardActions, Grid, Button, TextField, CircularProgress, InputAdornment, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DropImage from '~/components/DropImage';
import clsx from 'clsx';
import { Formik } from 'formik';

const useStyles = makeStyles(() => ({
 root: {},
 buttonProgress: {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12
 }
}));

function EditarHistorico(props) {
 let { handleSubmit, historico } = props;
 const { loading } = useSelector(state => state.historico);

 const classes = useStyles();
 return (
  <Formik initialValues={historico} onSubmit={handleSubmit}>
   {props => {
    const { values, errors, handleBlur, handleSubmit, setFieldValue, handleChange } = props;

    return (
     <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <CardContent>
       <Grid container spacing={3}>
        <Grid item container spacing={3} direction="row" alignItems="center" justify="space-around">
         <TextField
          onChange={handleChange}
          type="number"
          label="Peso"
          id="weight"
          name="weight"
          value={values.weight}
          className={clsx(classes.margin, classes.textField)}
          InputProps={{
           endAdornment: <InputAdornment position="end">kg</InputAdornment>
          }}
          variant="outlined"
         />
         <TextField
          onChange={handleChange}
          type="number"
          label="Altura"
          id="height"
          name="height"
          value={values.height}
          className={clsx(classes.margin, classes.textField)}
          InputProps={{
           endAdornment: <InputAdornment position="end">cm</InputAdornment>
          }}
          variant="outlined"
         />
        </Grid>
        <Grid item md={6} xs={12}>
         <TextField
          fullWidth
          multiline
          rows={5}
          value={values.costumer_complaint}
          autoComplete="on"
          label="Queixas do paciente"
          margin="dense"
          onChange={handleChange}
          variant="outlined"
          id="costumer_complaint"
          name="costumer_complaint"
          error={Boolean(errors.costumer_complaint)}
          onBlur={handleBlur}
         />
        </Grid>
        <Grid item md={6} xs={12}>
         <TextField
          fullWidth
          multiline
          rows={5}
          value={values.physical_exam}
          autoComplete="on"
          label="Exame físico"
          margin="dense"
          onChange={handleChange}
          variant="outlined"
          id="physical_exam"
          name="physical_exam"
          error={Boolean(errors.physical_exam)}
          onBlur={handleBlur}
         />
        </Grid>
        <Grid item md={12} xs={12}>
         <TextField
          multiline
          value={values.conduct}
          rows={5}
          fullWidth
          autoComplete="on"
          onChange={handleChange}
          label="Conduta médica"
          margin="dense"
          variant="outlined"
          id="conduct"
          name="conduct"
          error={Boolean(errors.conduct)}
          onBlur={handleBlur}
         />
        </Grid>
        <Grid item md={12} xs={12}>
         <DropImage images={values.images} handleChange={setFieldValue} urlAPI="histories" />
        </Grid>
        <Grid item md={12} xs={12}>
         <TextField
          multiline
          rows={10}
          value={values.conclusion}
          fullWidth
          autoComplete="on"
          onChange={handleChange}
          label="Observações"
          margin="dense"
          variant="outlined"
          id="conclusion"
          name="conclusion"
          error={Boolean(errors.conclusion)}
          onBlur={handleBlur}
         />
        </Grid>
       </Grid>
      </CardContent>
      <CardActions>
       <Button type="submit" color="primary" variant="contained" disabled={loading}>
        Finalizar
        {loading && <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />}
       </Button>
      </CardActions>
     </form>
    );
   }}
  </Formik>
 );
}

export default EditarHistorico;
