import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import StarsIcon from '@material-ui/icons/Stars';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/styles';
import history from '~/services/history';
import * as AlertActions from '~/store/modules/alert/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import Cliente from '~/views/Avaliacoes/Cliente';

import api from '../../services/api';
import Add from './components/Add';
import C1ModelToPrint from './components/Add/C1/c1-model-to-print';
import { ModelToPrint } from './components/Add/model-to-print';
import List from './components/List';

const useStyles = makeStyles(theme => ({
 root: {
  padding: theme.spacing(3)
 },
 content: {
  marginTop: theme.spacing(2)
 },
 pagination: {}
}));

const PRESCRIPTION_TYPE = {
 CO: 'CO',
 SC: 'SC'
};

function Prescriptions() {
 const { id_cliente } = useParams();
 const { profile } = useSelector(state => state.user);
 const dispatch = useDispatch();
 const classes = useStyles();
 const prescriptionComponentRef = useRef();
 const prescriptionC1ComponentRef = useRef();
 const onBeforeGetContentResolve = useRef(null);
 const onBeforePrintResolve = useRef(null);

 const [isPrinting, setIsPrinting] = useState(false);
 const [prescriptions, setPrescriptions] = useState([]);
 const [pagination, setPagination] = useState(1);
 const [activePage, setActivePage] = useState(1);
 const [totalPrescriptions, setTotalPrescriptions] = useState(0);
 const [customer, setCustomer] = useState({});
 const [company, setCompany] = useState({});
 const [appointments, setAppointments] = useState([]);
 const [exams, setExams] = useState([]);
 const [newPrescription, setNewPrescription] = useState(false);
 const [prescription, setPrescription] = useState({});
 const [prescriptionContent, setPrescriptionContent] = useState(null);
 const [prescriptionToSaveAndPrint, setPrescriptionToSaveAndPrint] = useState(null);

 const componentsToPrint = {
  PrescriptionCommon: prescriptionComponentRef,
  PrescriptionC1: prescriptionC1ComponentRef
 };

 useEffect(() => {
  async function loadCustomerPrescriptions() {
   dispatch(LoadingActions.setLoading());
   const res = await api.get(`/customers/${id_cliente}/prescriptions`, {
    params: {
     page: activePage
    }
   });
   let qtyPages = 1;
   if (res.data.count / 10 > 0) {
    qtyPages = res.data.count / 10 + 1;
   }
   setTotalPrescriptions(res.data.count);
   setPagination(~~qtyPages);
   setPrescriptions(res.data.results);
   dispatch(LoadingActions.closeLoading());
  }
  loadCustomerPrescriptions();
 }, [id_cliente, activePage]);

 useEffect(() => {
  async function loadCustomer() {
   const res = await api.get(`/customers/${id_cliente}`);
   setCustomer(res.data);
  }
  loadCustomer();
 }, [id_cliente]);

 useEffect(() => {
  async function loadCompany() {
   const res = await api.get(`/companies/${profile.company_id}`);
   setCompany(res.data);
  }
  loadCompany();
 }, [id_cliente, profile.company_id]);

 useEffect(() => {
  async function loadAppointments() {
   const res = await api.get(`/customers/${id_cliente}/appointments`);
   setAppointments(res.data.results);
  }
  if (newPrescription) {
   loadAppointments();
  }
 }, [id_cliente, newPrescription]);

 useEffect(() => {
  async function loadExams() {
   const res = await api.get(`/customers/${id_cliente}/exams`);
   setExams(res.data.results);
  }
  if (newPrescription) {
   loadExams();
  }
 }, [id_cliente, newPrescription]);

 const handleChangePage = (event, value) => {
  window.scrollTo(0, 0);
  setActivePage(value);
 };

 const handleSaveEditPrescription = useCallback(async () => {
  dispatch(LoadingActions.setLoading());
  if (prescriptionToSaveAndPrint?.prescription_id) {
   await api
    .put(`/prescriptions/${prescriptionToSaveAndPrint?.prescription_id}`, prescriptionToSaveAndPrint)
    .then(res => {
     setPrescription({});
     setNewPrescription(!newPrescription);
     dispatch(AlertActions.success('Receita salva com sucesso!'));
    })
    .catch(err => {
     dispatch(AlertActions.error('Erro ao tentar salvar a receita.'));
    });
   dispatch(LoadingActions.closeLoading());
   return;
  }

  await api
   .post('/prescriptions', prescriptionToSaveAndPrint)
   .then(res => {
    setPrescription({});
    setNewPrescription(!newPrescription);
    setPrescriptions([res.data, ...prescriptions]);
    dispatch(AlertActions.success('Nova receita salva com sucesso!'));
   })
   .catch(err => {
    dispatch(AlertActions.error('Erro ao tentar salvar a receita.'));
   });
  dispatch(LoadingActions.closeLoading());
 }, [prescriptionToSaveAndPrint]);

 const handleSubmitNewPrescription = useCallback(
  async (prescription, print, contentToPrint) => {
   setPrescriptionContent(prescription?.text);

   prescription = {
    ...prescription,
    customer_id: customer.customer_id,
    appointment_id: prescription?.appointment_id ? prescription?.appointment_id?.appointment_id : null,
    exam_id: prescription?.exam_id ? prescription?.exam_id?.exam_id : null
   };

   setPrescriptionToSaveAndPrint(prescription);

   if (print) {
    handlePrint(null, () => contentToPrint.current);
   }
  },
  [customer, newPrescription]
 );

 const handleOnBeforeGetContent = useCallback(
  async values => {
   return await new Promise(resolve => {
    onBeforeGetContentResolve.current = resolve;
   });
  },
  [setPrescriptionContent, prescriptionContent]
 );

 useEffect(() => {
  if (prescriptionContent !== null && typeof onBeforeGetContentResolve.current === 'function') {
   onBeforeGetContentResolve.current();
  }
 }, [onBeforeGetContentResolve.current, prescriptionContent]);

 const handleOnBeforPrint = useCallback(async () => {
  return await new Promise(resolve => {
   onBeforePrintResolve.current = resolve;
   setIsPrinting(true);
  });
 }, []);

 useEffect(() => {
  if (isPrinting && typeof onBeforePrintResolve.current) {
   onBeforePrintResolve.current();
   handleSaveEditPrescription();
   setIsPrinting(false);
  }
 }, [isPrinting]);

 const handleEditPrescription = useCallback(prescription => {
  setNewPrescription(!newPrescription);
  setPrescription(prescription);
 }, []);

 const handleComeBackPrescriptions = useCallback(() => {
  setNewPrescription(!newPrescription);
  setPrescription({});
 }, [newPrescription]);

 const handleDeletePrescription = useCallback(async prescription => {
  setPrescription({});
  setNewPrescription(!newPrescription);
  dispatch(LoadingActions.setLoading());

  await api
   .delete(`/prescriptions/${prescription.prescription_id}`)
   .then(res => {
    history.push(`/pacientes/detalhes/${prescription.customer_id}`);
    dispatch(AlertActions.success('Receita deletada com sucesso!'));
   })
   .catch(err => {
    dispatch(AlertActions.error('Erro ao deletar receita.'));
   });

  dispatch(LoadingActions.closeLoading());
 }, []);

 const handlePrint = useReactToPrint({
  documentTitle: `Receita - ${customer?.name}`,
  //   content: () => componentRef.current,
  onBeforePrint: handleOnBeforPrint
  //   onAfterPrint: () => history.push(`/pacientes/detalhes/${customer.customer_id}`),
  //   onBeforeGetContent: handleOnBeforeGetContent
  //   removeAfterPrint: true
 });

 const addNewPrescription = useCallback(type => {
  setNewPrescription(!newPrescription);
  setPrescription({ type });
 }, []);

 return (
  <Grid container spacing={2} className={classes.root}>
   <Grid item xs={12}>
    <Cliente totalPrescriptions={totalPrescriptions} customer={customer} />
   </Grid>
   {!newPrescription ? (
    <Grid container spacing={2} justifyContent="flex-end" item>
     <Grid item>
      <Button
       startIcon={<LibraryBooksIcon />}
       onClick={() => addNewPrescription(PRESCRIPTION_TYPE.CO)}
       variant="contained"
       color="primary">
       Adicionar Receita Comum
      </Button>
     </Grid>
     <Grid item>
      <Button
       startIcon={<StarsIcon />}
       onClick={() => addNewPrescription(PRESCRIPTION_TYPE.SC)}
       variant="outlined"
       color="primary">
       Adicionar Receita Controle Especial
      </Button>
     </Grid>
    </Grid>
   ) : (
    <Grid container justifyContent="flex-start" item xs={12}>
     <IconButton onClick={handleComeBackPrescriptions} variant="contained" color="primary">
      <ArrowBackIcon />
     </IconButton>
    </Grid>
   )}
   <Grid item xs={12}>
    {newPrescription ? (
     <>
      <Add
       prescription={prescription}
       handleSubmit={handleSubmitNewPrescription}
       appointments={appointments}
       exams={exams}
       cancel={handleComeBackPrescriptions}
       componentsToPrint={componentsToPrint}
      />
     </>
    ) : (
     <Grid container spacing={2}>
      {prescriptions.map(pres => (
       <Grid key={pres?.prescription_id} item xs={12}>
        <List prescription={pres} handleEditPrescription={handleEditPrescription} />
       </Grid>
      ))}
     </Grid>
    )}
   </Grid>
   {!newPrescription && (
    <Grid item xs={12} container justify="center">
     {totalPrescriptions !== 0 ? (
      <Pagination onChange={handleChangePage} count={pagination} color="primary" />
     ) : (
      <Typography variant="h2" color="textPrimary">
       Não há receitas para esse paciente.
      </Typography>
     )}
    </Grid>
   )}
   <div style={{ display: 'none' }}>
    <C1ModelToPrint
     companyLogo={company?.company_logo}
     professionalFirstName={profile?.first_name}
     professionalLastName={profile?.last_name}
     professionalDocument={profile?.professional_document}
     professionalSpecialty={profile?.specialty}
     professionalTitle={profile?.title}
     companyAddress={company?.address}
     companyNumber={company?.number_address}
     companyNeighborhood={company?.neighborhood}
     companyCity={company?.city}
     companyPhone={company?.phone}
     patientName={customer?.name}
     patientAge={customer?.age}
     patientAddress={customer?.address}
     patientAddressNumber={customer?.number_address}
     patientNeighborhood={customer?.neighborhood}
     patientCity={customer?.city}
     patientPostalCode={customer?.postal_code}
     content={prescriptionContent}
     ref={prescriptionC1ComponentRef}
    />
   </div>
   <div style={{ display: 'none' }}>
    <ModelToPrint
     companyLogo={company?.company_logo}
     professionalFirstName={profile?.first_name}
     professionalLastName={profile?.last_name}
     professionalDocument={profile?.professional_document}
     professionalSpecialty={profile?.specialty}
     companyAddress={company?.address}
     companyNumber={company?.number_address}
     companyNeighborhood={company?.neighborhood}
     companyCity={company?.city}
     companyPhone={company?.phone}
     patientName={customer?.name}
     patientAge={customer?.age}
     content={prescriptionContent}
     ref={prescriptionComponentRef}
    />
   </div>
  </Grid>
 );
}

export default Prescriptions;
