import React from 'react';
import { GrAccessibility } from 'react-icons/gr';

import { CardHeader, CardContent, CardActions, Grid, Divider, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import BoxAvaliacao from '~/components/BoxAvaliacao';
import ImageThumbnail from '~/components/ImageThumbnail';
import {} from './styles';
import UpdateAt from '~/components/UpdateAt';

const useStyles = makeStyles(() => ({
 thumb: {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  '&:hover': {
   width: 120,
   height: 120,
   transition: '0.5s'
  },
  borderRadius: 5,
  '&:hover': {
   opacity: 0.5,
   transition: '0.5s'
  }
 },
 thumbInner: {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
 },
 img: {
  display: 'block',
  width: 'auto',
  height: '100%'
 },
 excludeThumb: {
  position: 'absolute',
  float: 'right'
 }
}));

function Avaliacao(props) {
 const { historico, handleEditarAvaliacao, handleOpenFoto, cancel } = props;

 const classes = useStyles();

 return (
  <>
   <CardHeader title="Prontuário" />
   <Divider />
   {historico?.length === 0 ? (
    <h2>Não há pronturário para esse atendimento.</h2>
   ) : (
    historico?.map(hist => (
     <>
      <CardContent key={hist.historic_id}>
       <Grid container spacing={2} justify="space-between">
        <Grid item container direction="row" spacing={2} alignItems="center">
         {hist.weight && (
          <Grid item xs={6}>
           <Typography>Peso: {hist.weight} kg</Typography>
          </Grid>
         )}
         {hist.height && (
          <Grid item xs={6}>
           <Typography>Altura: {hist.height} cm</Typography>
          </Grid>
         )}
        </Grid>
        <Grid item xs={12}>
         <BoxAvaliacao
          title="Queixas do paciente"
          value={hist.costumer_complaint}
          icon={<GrAccessibility size={50} />}
         />
        </Grid>
        <Grid item xs={12}>
         <BoxAvaliacao title="Exame físico" value={hist.physical_exam} icon={<GrAccessibility size={50} />} />
        </Grid>
        <Grid item xs={12}>
         <BoxAvaliacao title="Conduta médica" value={hist.conduct} icon={<GrAccessibility size={50} />} />
        </Grid>
        <Grid item xs={12}>
         <Grid container justify="center">
          {hist.images
           ? hist.images.map((file, index) => (
              <ImageThumbnail
               key={index}
               index={index}
               images={hist?.images}
               file={file}
               handleOpenFoto={handleOpenFoto}
              />
             ))
           : 'Sem arquivos'}
         </Grid>
        </Grid>
        <Grid item xs={12}>
         <BoxAvaliacao title="Observações" value={hist.conclusion} icon={<GrAccessibility size={50} />} />
        </Grid>
       </Grid>
      </CardContent>
      <Divider />
      <Grid container justify="space-between" alignItems="center">
       <Grid item>
        <UpdateAt
         firstName={hist?.updated_by?.first_name}
         lastName={hist?.updated_by?.last_name}
         updateAt={hist?.updated_at}
        />
       </Grid>
       <Grid item>
        <CardActions>
         {handleEditarAvaliacao && (
          <Button
           onClick={() => handleEditarAvaliacao(hist.appointment_id)}
           variant="contained"
           color={hist.read ? '' : 'primary'}>
           {hist.read ? 'Editar' : 'Iniciar'}
          </Button>
         )}
        </CardActions>
       </Grid>
      </Grid>
     </>
    ))
   )}
  </>
 );
}

export default Avaliacao;
